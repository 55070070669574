<template>
  <div class="m-2 ">
    <arq-previews-page />
    <h1>{{ process.title }}</h1>
    <form class="col col-md-6 col-sm-2 mx-auto  my-4">
      <div class="card p-2">
        <div
          v-for="(form, index) in process.form"
          :key="index"
        >
          <div :class="form.hidden ? 'd-none' : ''">
            <label :for="form.key">
              {{ form.label }}
            </label>
            <div v-if="form.key.includes('identification')">
              {{ data[form.key] }}
              <b-form-input
                :id="form.key"
                v-model="data[form.key]"
                :name="form.key"
                :type="form.type"
                :required="form.required"
                :disabled="form.disabled"
              />
            </div>
            <div v-if="form.key.includes('phone')">
              <b-form-input
                :id="form.key"
                v-model="phone"
                :name="form.key"
                :type="form.type"
                :required="form.required"
                :disabled="form.disabled"
              />
            </div>
            <div v-if="form.key.includes('email')">
              <b-form-input
                :id="form.key"
                v-model="email"
                :name="form.key"
                :type="form.type"
                :required="form.required"
                :disabled="form.disabled"
              />
            </div>
            <div
              v-if="
                !form.key.includes('email') &&
                  !form.key.includes('phone') &&
                  !form.key.includes('identification')
              "
            >
              <div v-if="types.includes(form.type)">
                <b-form-input
                  :id="form.key"
                  v-model="data[form.key]"
                  :name="form.key"
                  :type="form.type"
                  :disabled="form.disabled"
                />
              </div>
            </div>
            <div v-if="form.type === 'select-contract'">
              <b-form-select
                :id="form.key"
                v-model.number="data[form.key]"
                value-field="idProducto"
                text-field="descservicio"
                :options="contratos"
                @input="getOptionServices(data[form.key])"
              />
            </div>
            <div v-if="form.type === 'select-services'">
              <b-form-select
                :id="form.key"
                v-model.number="data[form.key]"
                value-field="idProducto"
                text-field="descservicio"
                :options="optionsServices"
              />
            </div>
            <!-- :state="Boolean(data[form.key])" -->
            <b-form-file
              v-if="form.type === 'file'"
              :id="form.key"
              v-model="data[form.key]"
              :name="form.name"
              placeholder="Adjuntar documento..."
              drop-placeholder="Suelta el documento aqui..."
            />
            <div v-if="form.type === 'textarea'">
              <b-form-textarea
                :id="form.key"
                v-model="data[form.key]"
                rows="8"
              />
            </div>
          </div>
        </div>
        <b-button
          variant="primary mt-3"
          @click="confirm()"
        >
          Confirmar
        </b-button>
      </div>
    </form>
    <!-- <pre>{{ data }}</pre> -->
    <b-modal
      id="modal-confirm-process"
      title="Tramite"
      ok-only
    >
      <div v-if="!response.message">
        <p class="my-4">
          <b-spinner
            variant="success"
            label="Spinning"
          />
          Enviando solicitud!
        </p>
      </div>
      <div v-else>
        <b-alert
          :variant="response.variant"
          show
        >
          {{ response.message }}
        </b-alert>
      </div>
    </b-modal>
    <b-modal
      id="modal-actualizar-datos-personales"
      title="Actualizar datos personales"
      hide-footer
    >
      <!-- ok-title="Confirmar"
      cancel-title="Cancelar"
      @ok="handleOk" -->
      <div v-if="!responseUpdatePersonal.message">
        <b-alert
          variant="danger"
          show
        >
          Necesita actualizar los datos personales
        </b-alert>
        <form
          ref="formActualizarDatosPersonales"
          @submit.stop.prevent="handleSubmit"
        >
          <!-- <b-form-group
            label="Identifiacion"
            label-for="identifiacion-input"
            invalid-feedback="Identificacion es requerida"
            :state="identificacionState"
          >
            <b-form-input
              id="identifiacion-input"
              v-model="dataPersonal.identificacion"
              required
              type="number"
              :state="identificacionState"
            />
          </b-form-group> -->
          <b-form-group
            label="Telefono"
            label-for="phone-input"
            invalid-feedback="Telefono es requerido"
            :state="phoneState"
          >
            <b-form-input
              id="phone-input"
              v-model="dataPersonal.celularespo"
              max-length="10"
              min-length="10"
              required
              type="number"
              :state="phoneState"
            />
          </b-form-group>
          <b-form-group
            label="Correo"
            label-for="email-input"
            invalid-feedback="Email es requerido"
            :state="emailState"
          >
            <b-form-input
              id="email-input"
              v-model="dataPersonal.emailresposerv"
              required
              type="email"
              :state="emailState"
            /></b-form-group>
        </form>
        <button
          class="bg-primary text-white m-auto mr-auto btn"
          style="position: relative; margin-left: 12em !important;"
          @click.prevent="handleOk"
        >
          Confirmar
        </button>
      </div>
      <div v-else>
        <b-alert
          :variant="responseUpdatePersonal.variant"
          show
        >
          a {{ responseUpdatePersonal.message }}
        </b-alert>
      </div>
    </b-modal>
    {{ observer }}
  </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'

export default {
  props: {
    urlFrame: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      phone: '',
      email: '',
      idService: '',
      response: {},
      processParam: this.$route.params.process,
      process: {},
      data: {},
      dataPersonal: {},
      optionsServices: [],
      responseUpdatePersonal: {},
      identificacionState: false,
      phoneState: false,
      emailState: false,
      types: [
        'text',
        'number',
        'email',
        'password',
        'search',
        'url',
        'tel',
        'date',
        'time',
        'range',
        'color',
      ],
      messageUpdatePersonal: '',
      listProcess: [
        {
          process: 'cambio-ciclo',
          title: 'Cambio De Ciclo',
          procedure: '/cambioCiclo',
          validaPhoneAndEmail: true,
          form: [
            {
              label: 'Contrato',
              key: 'contrato',
              type: 'number',
              required: true,
              hidden: true,
            },
            {
              label: 'Servicio',
              key: 'idService',
              type: 'select-services',
              required: true,
            },
            {
              label: 'Celular',
              key: 'phone',
              type: 'number',
              disabled: true,
              required: true,
            },
            {
              label: 'Correo',
              key: 'email',
              type: 'text',
              disabled: true,
              required: true,
            },
            {
              label: 'Descripcion',
              key: 'descripcion',
              type: 'textarea',
              required: true,
            },
          ],
        },
        {
          process: 'cambio-uso-estrato',
          title: 'Cambio De Uso Y Estrato',
          procedure: '/cambioUsoEstrato',
          validaPhoneAndEmail: true,
          form: [
            {
              label: 'Identificación',
              key: 'identification',
              type: 'number',
              required: true,
              hidden: true,
            },
            {
              label: 'Contrato',
              key: 'contrato',
              type: 'select-contract',
              required: true,
            },
            // {
            //   label: 'Servicio',
            //   key: 'idService',
            //   type: 'select-services',
            //   required: true,
            // },
            {
              label: 'Celular',
              key: 'phone',
              type: 'number',
              disabled: true,
              required: true,
            },
            {
              label: 'Correo',
              key: 'email',
              type: 'text',
              disabled: true,
              required: true,
            },
            {
              label: 'Seleccione la estratificación',
              key: 'file',
              name: 'files',
              type: 'file',
              disabled: true,
              required: true,
            },
            {
              label: 'Descripcion',
              key: 'descripcion',
              type: 'textarea',
              required: true,
            },
          ],
        },
        {
          process: 'impresion-duplicados',
          title: 'Impresión De Duplicados',
          procedure: '/impresionDuplicados',
          validaPhoneAndEmail: true,
          form: [
            {
              label: 'Contrato',
              key: 'contrato',
              type: 'number',
              required: true,
              hidden: true,
            },
            {
              label: 'Servicio',
              key: 'idService',
              type: 'select-services',
              required: true,
            },
            {
              label: 'Celular',
              key: 'phone',
              type: 'number',
              disabled: true,
              required: true,
            },
            {
              label: 'Correo',
              key: 'email',
              type: 'text',
              disabled: true,
              required: true,
            },
            {
              label: 'Descripcion',
              key: 'descripcion',
              type: 'textarea',
              required: true,
            },
          ],
        },
        {
          process: 'actualizacion-datos-personales',
          title: 'Actualización de datos personales',
          procedure: '/actualizacionDatosPersonales',
          validaPhoneAndEmail: true,
          form: [
            {
              label: 'Contrato',
              key: 'contrato',
              type: 'number',
              required: true,
              hidden: true,
            },

            {
              label: 'Celular',
              key: 'phone',
              type: 'number',
              disabled: true,
              required: true,
            },
            {
              label: 'Correo',
              key: 'email',
              type: 'text',
              disabled: true,
              required: true,
            },
          ],
        },
        {
          process: 'descargar-duplicados',
          title: 'Descarga de Duplicados',
          procedure: '/descargaDuplicado',
          method: 'get',
          form: [
            {
              label: 'Contrato',
              key: 'contrato',
              type: 'number',
              required: true,
            },
          ],
        },
      ],
    }
  },
  computed: {
    observer() {
      const { contrato } = this.data
      if (contrato) {
        this.getOptionServices(contrato)
      }
      return null
    },
    ...mapState('client', ['contratos']),
  },
  watch: {
    'data.file': function (el) {
      this.data.fileName = el.name
      this.data.fileType = el.type
      this.getBase64(el)
    },
  },
  created() {
    this.process = this.listProcess.find(el => el.process === this.processParam)
    // console.log('process', this.process)
  },
  mounted() {
    this.data.identification = this.$store.state.client.client.identificacion
    this.data.email = this.$store.state.client.client.emailresposerv
    this.data.phone = this.$store.state.client.client.celularespo
  },
  methods: {
    getBase64(file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      const self = this
      reader.onload = function () {
        const r = reader.result
        //  console.log(r)
        //  console.log('self', self)
        const base = r.split(',')[1]
        self.data.fileBase64 = base
      }
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      let validate = false
      // if (!this.dataPersonal.identificacion) {
      //   validate = this.checkFormValidity()
      //   if (!validate) {
      //     // console.log('blos')
      //     return false
      //   }
      //   return false
      // }
      if (!this.dataPersonal.celularespo && !this.dataPersonal.emailresposer) {
        validate = this.checkFormValidity()
        if (!validate) {
          // this.identificacionState = true
          // console.log('blos')
          return false
        }
      }

      // Prevent modal from closing
      validate = this.checkFormValidity()
      this.phoneState = true
      this.emailState = true
      // this.identificacionState = true
      // console.log('bla')
      this.dataPersonal.identificacion = this.data.identification
      axios
        .post('/app/person/update', this.dataPersonal)
        .then(res => {
          if (res.data.code === 0) {
            this.responseUpdatePersonal.message = 'Sus datos han sido actualizados exitosamente.'
            this.responseUpdatePersonal.variant = 'success'
            this.data = {}
          } else {
            this.responseUpdatePersonal.message = 'Error al intentar actualizar los datos.'
            this.responseUpdatePersonal.variant = 'danger'
          }
        })
        .finally(() => {
          this.dataPersonal = {}
        })
      this.$nextTick(() => {
        this.$bvModal.hide('modal-actualizar-datos-personales')
      })
      return true
      // Trigger submit handler
    },
    checkFormValidity() {
      const valid = this.$refs.formActualizarDatosPersonales.checkValidity()
      return valid
    },
    confirm() {
      // console.log('this.data.phone ', this.data.phone)
      if (this.process.validaPhoneAndEmail) {
        if (
          this.data.phone === ''
          || this.phone === null
          || !this.phone
          || !this.email
          || this.email === ''
          || this.email === null
        ) {
          this.openActualizacionDatos()
          return
        }
      }

      this.$bvModal.show('modal-confirm-process')
      if (this.process.method === 'get') {
        this.sendGetProcedure(this.process.procedure, this.data)
          .then(res => {
            if (res.data.code === 0) {
              this.response = res.data
              this.response.message = res.data.msg || 'Su tramite ha sido enviado'
              this.response.variant = 'success'
            } else {
              this.response = res.data
              this.response.message = res.data.msg || 'Error al procesar su tramite'
              this.response.variant = 'danger'
            }
          })
          .catch(err => {
            this.response = err
            this.response.message = err.msg || 'Error al procesar su tramite'
            this.response.variant = 'danger'
          })
      }
      this.data.idService = this.idService
      this.data.phone = this.phone
      this.data.email = this.email
      this.sendProcedure(this.process.procedure, this.data)
        .then(res => {
          if (res.data.code === 0) {
            this.response = res.data
            this.response.message = res.data.msg || 'Su tramite ha sido enviado'
            this.response.variant = 'success'
          } else {
            this.response = res.data
            this.response.message = res.data.msg || 'Error al procesar su tramite'
            this.response.variant = 'danger'
          }
        })
        .catch(err => {
          this.response = err
          this.response.message = err.msg || 'Error al procesar su tramite'
          this.response.variant = 'danger'
        })
    },
    async sendProcedure(procedure, formData) {
      // const data = JSON.stringify(formData)
      return new Promise((resolve, reject) => {
        axios
          .post(`/app/procedure${procedure}`, formData)
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    async sendGetProcedure(procedure, formData) {
      // const data = JSON.stringify(formData)
      return new Promise((resolve, reject) => {
        axios
          .get(`/app/procedure${procedure}`, formData)
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    openActualizacionDatos() {
      this.$bvModal.show('modal-actualizar-datos-personales')
    },
    getOptionServices(contrato) {
      axios
        .get(`/app/services/fromContract?idContract=${contrato}`)
        .then(res => {
          if (res.data.code === 0) {
            this.optionsServices = res.data.list
            this.idService = res.data.object.idProductoDefault
            this.phone = res.data.object.celularespo
            this.email = res.data.object.emailresposerv
          } else {
            this.optionsServices = []
          }
        })
    },
  },
}
</script>

<style></style>
